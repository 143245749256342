import { useEffect, useRef, useState } from 'react';
import { constants } from '../Constants';
import { Icon } from '@iconify/react';
import { Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { Checkbox, FormControlLabel, TextField } from '@mui/material';
import useFetch from '../../useFetch';
import { useSelector } from 'react-redux';
import { removeUser } from '../../redux/userSlice';
import { store } from '../../redux/store';

const RegisterInfo = () => {
    document.title = "Register Information | Mowing";
    
    let navigate = useNavigate();
    
    const { user } = useSelector(state => state.user);
    
    useEffect(() => {
        if (!user.token) navigate('/login')
        else if (user.artistName == '-NA-') navigate('/artists/personal-information')
    }, [user])
    
    const fileRef = useRef();
        
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    
    const [file, setFile] = useState(null);
    const [name, setName] = useState('');
    const [idNumber, setIdNumber] = useState('')
    const [bankAccount, setBankAccount] = useState('')
    const [accountOwnerName, setAccountOwnerName] = useState('')
    
    const [apiUrl, setApiUrl] = useState(process.env.REACT_APP_BASE_API_URL+'/bank/information');
    
    const { response, isLoadingFetch, errorFetch } = useFetch(apiUrl);
    
    useEffect(() => {
        if (response?.bank) {
            setName(response?.bank?.fullName ?? '');
            setIdNumber(response?.bank?.idNumber ?? '')
            setBankAccount(response?.bank?.bankAccount ?? '')
            setAccountOwnerName(response?.bank?.accountOwnerName ?? '')
        }
    }, [response])
    
    const handleSubmit = (e) => {
        console.log('asd')
        e.preventDefault();
        
        setIsLoading(true);
        setSuccess(false);
        setError(false);

        let formData = new FormData();
        formData.append("_method", "PUT");
        formData.append("fullName", name)
        formData.append("idNumber", idNumber)
        formData.append("bankAccount", bankAccount)
        formData.append("accountOwnerName", accountOwnerName)
        formData.append("doc", file)

        fetch(process.env.REACT_APP_BASE_API_URL+'/bank/information', {
            method: 'PUT',
            headers: { 
                "Accept": "application/json",
                "Authorization": "Bearer "+localStorage.getItem("token")
            },
            body: formData
        })
        .then(response => {
            if (!response.ok) {
                if (response.status == 401)
                {
                    store.dispatch(removeUser());
                    throw Error('Please login to update information.');
                }
                else
                    throw Error('There was some error while updating information, please try again.');
            }

            return response.json();
        })
        .then(data => {
            setIsLoading(false);

            if (data.success == true)
            {
                setSuccess('Information updated successfully');

                setFile(null);

                setApiUrl(process.env.REACT_APP_BASE_API_URL+'/bank/information?_='+Date.now())

                if (fileRef?.current)
                {
                    fileRef.current.value = '';
                }
            }
            else if (data.success == false)
            {
                setError(data.message);
            }
            else
            {
                setError('There was some error while updating information, please try again.');
            }
        })
        .catch(error => {
            setIsLoading(false);
            setError(error.message);
        })
    }

    return (
        <div className="container-fluid artist-details mb-4 pt-2">
            <div className="row justify-content-center flex-fill">
                <div className="col-xl-6 col-lg-7 col-md-8 col-sm-10">
                    <div className='general-card mb-4'>
                        <div className='general-card-header'>
                            <h4 className="heading1 mb-0">Register Information</h4>
                        </div>

                        <div className="card-body">
                            { error && <div className="alert-msg-danger"><h3 className="mb-0">{ error }</h3></div> }
                            { success && <div className="alert-msg-success"><h3 className="mb-0">{ success }</h3></div> }
                            
                            { response?.bank?.status === 1 ? (
                                <>
                                    <div className="alert-msg-warning"><h3 className="mb-0"><strong>Confirmation of entered data is in progress, please wait.<br />You will be able to update information after we approve or reject your information.</strong></h3></div>

                                    <label className="fake-label">Full name</label>
                                    <p>{response?.bank?.fullName ?? '-'}</p>

                                    <label className="fake-label">ID number</label>
                                    <p>{response?.bank?.idNumber ?? '-'}</p>

                                    <label className="fake-label">Bank account</label>
                                    <p>{response?.bank?.bankAccount ?? '-'}</p>

                                    <label className="fake-label">Account owner name</label>
                                    <p>{response?.bank?.accountOwnerName ?? '-'}</p>

                                    <label className="fake-label">Image of bankbook</label>
                                    <p className='mb-0'><a href={process.env.REACT_APP_BASE_FILES_URL+response?.bank?.fileUrl} target="_blank">View file</a></p>
                                </>
                            ) : (
                                <>
                                    { response?.bank === null && <div className="alert-msg-warning"><h3 className="mb-0"><strong>Please enter your bank information to convert hearts.</strong></h3></div> }
                                    { response?.bank?.status === 2 && <div className="alert-msg-success"><h3 className="mb-0"><strong>Your information has been approved.<br />Changing information will go through verification again.</strong></h3></div>}
                                    { response?.bank?.status === 3 && <div className="alert-msg-danger"><h3 className="mb-0"><strong>Your information has been rejected.<br />Please resubmit information.</strong></h3></div>}
                                    
                                    <form onSubmit={handleSubmit} className="user">
                                        <TextField 
                                            variant="outlined" 
                                            label="Full name" 
                                            value={name}
                                            onChange={ (e) => setName(e.target.value) }
                                            required={true}
                                            style={{ width: '100%' }}
                                            className="mb-3"
                                            />
                                            
                                        <TextField 
                                            variant="outlined" 
                                            label="ID number" 
                                            value={idNumber}
                                            onChange={ (e) => setIdNumber(e.target.value) }
                                            required={true}
                                            style={{ width: '100%' }}
                                            className="mb-3"
                                            />

                                        <TextField 
                                            variant="outlined" 
                                            label="Bank account" 
                                            value={bankAccount}
                                            onChange={ (e) => setBankAccount(e.target.value) }
                                            required={true}
                                            style={{ width: '100%' }}
                                            className="mb-3"
                                            />
                                            
                                        <TextField 
                                            variant="outlined" 
                                            label="Account owner name" 
                                            value={accountOwnerName}
                                            onChange={ (e) => setAccountOwnerName(e.target.value) }
                                            required={true}
                                            style={{ width: '100%' }}
                                            className="mb-3"
                                            />

                                        <label className="fake-label">Image of bankbook</label>

                                        <input 
                                            type="file" 
                                            ref={fileRef}
                                            onChange={(e) => setFile(e.target.files[0])}
                                            required={true}
                                            className="mb-3"
                                            accept=".jpeg,.jpg,.png,.pdf" 
                                            multiple="multiple"/>
                                        
                                        <button 
                                            type="submit" 
                                            disabled={isLoading}
                                            className="btn btn-secondary btn-user btn-block"
                                            >Update Information</button>
                                    </form>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default RegisterInfo;