import { useEffect, useState } from 'react';
import { constants } from './../Constants';
import { Icon } from '@iconify/react';
import { Button, Table } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useFetch from '../../useFetch';
import { printDate } from '../../Helpers/dateFormat';
import { store } from '../../redux/store';
import { removeUser } from '../../redux/userSlice';

const ExchangeDetail = () => {
    document.title = "Exchange Details | Mowing";
    
    let navigate = useNavigate();
        
    const { id } = useParams();

    const { user } = useSelector(state => state.user);
    
    useEffect(() => {
        if (!user.token) navigate('/login')
        else if (user.artistName == '-NA-') navigate('/artists/personal-information')
    }, [user])

    const [status, setStatus] = useState(null)

    const [hearts, setHearts] = useState(0);
    const [taxType1, setTaxType1] = useState(0);
    const [taxType2, setTaxType2] = useState(0);
    
    const { response, isLoadingFetch, errorFetch } = useFetch(process.env.REACT_APP_BASE_API_URL+'/exchange/details/'+id);
    
    useEffect(() => {
        if (response?.exchange) {
            setStatus(response?.exchange?.status)
            
            setHearts(response?.exchange?.hearts)
            var tax1 = (response?.exchange?.hearts * 240) * 0.03
            tax1 = Math.floor(tax1 / 10) * 10
            var tax2 = tax1 / 10
            tax2 = Math.floor(tax2 / 10) * 10

            setTaxType1(tax1)
            setTaxType2(tax2)
        }
    }, [response])

    return (
        <div className="container-fluid artist-details mb-4 pt-2">
            <div className="row justify-content-center flex-fill">
                <div className="col-xl-6 col-lg-7 col-md-8 col-sm-10">
                    <div className='general-card mb-4'>
                        <div className='general-card-header d-flex justify-content-between'>
                            <h4 className="heading1 mb-0">Exchange Details</h4>
                            <Link onClick={() => navigate(-1)} className="go-back mb-0">Go Back</Link>
                        </div>

                        <div className="py-4">
                            {isLoadingFetch ? (
                                <div className='text-center my-3'>
                                    <img src='/loader.svg' style={{ width: '80px', height: '80px' }} />
                                </div>
                            ) : (
                                errorFetch ? (
                                    <h5 className='text-center text-danger py-4 mb-0'>{errorFetch}</h5>
                                ) : (
                                    <>
                                        <div className='d-flex justify-content-between align-items-center mb-3 font-18 px-4'>
                                            <p className='mb-0'>Date</p>
                                            <p className='mb-0'>{printDate(response?.exchange?.createdAt, true)}</p>
                                        </div>

                                        <div className='d-flex justify-content-between align-items-center mb-3 font-18 px-4'>
                                            <p className='mb-0'>Status</p>
                                            <div>
                                                {status == 1 ? <span className={`badge badge-table badge-warning`}>Pending</span> : null}
                                                {status == 2 ? <span className={`badge badge-table badge-success`}>Approved</span> : null}
                                                {status == 3 ? <span className={`badge badge-table badge-danger`}>Rejected</span> : null}
                                            </div>
                                        </div>

                                        <hr />

                                        <div className='d-flex justify-content-between align-items-center mb-3 font-18 px-4'>
                                            <p className='mb-0'>Hearts to Exchange</p>
                                            <p className='mb-0'>{hearts}</p>
                                        </div>

                                        <hr />

                                        <div className='px-4'>
                                            <div className='d-flex justify-content-between align-items-center mb-3 font-18'>
                                                <p className='mb-0'>Requested Amount
                                                    <span style={{ fontWeight: '600', marginLeft: '7px', position: 'relative', top: '-1px' }}>
                                                        <Icon icon={'tabler:circle-letter-a'} width={22} height={22} />
                                                    </span>
                                                </p>
                                                <p className='mb-0'>{hearts * 240}원</p>
                                            </div>
                                            
                                            <div className='d-flex justify-content-between align-items-center mb-3 font-18'>
                                                <div className='mb-0' style={{ lineHeight: '1'}}>
                                                    <div>Tax Type 1
                                                        <span style={{ fontWeight: '600', marginLeft: '7px', position: 'relative', top: '-1px' }}>
                                                            <Icon icon={'tabler:circle-letter-b'} width={22} height={22} />
                                                        </span>
                                                    </div>
                                                    <span style={{ fontWeight: '500', fontSize: '14px' }}>
                                                        (<Icon icon={'tabler:circle-letter-b'} width={16} height={16} /> = <Icon icon={'tabler:circle-letter-a'} width={16} height={16} /> * 0.03) 원단위 절사
                                                    </span>
                                                </div>
                                                <p className='mb-0'>{taxType1}원</p>
                                            </div>

                                            <div className='d-flex justify-content-between align-items-center mb-3 font-18'>
                                                <div className='mb-0' style={{ lineHeight: '1'}}>
                                                    <div>Tax Type 2
                                                        <span style={{ fontWeight: '600', marginLeft: '7px', position: 'relative', top: '-1px' }}>
                                                            <Icon icon={'tabler:circle-letter-c'} width={22} height={22} />
                                                        </span>
                                                    </div>
                                                    <span style={{ fontWeight: '500', fontSize: '14px' }}>
                                                        (<Icon icon={'tabler:circle-letter-c'} width={16} height={16} /> = <Icon icon={'tabler:circle-letter-b'} width={16} height={16} /> / 10) 원단위 절사
                                                    </span>
                                                </div>
                                                <p className='mb-0'>{taxType2}원</p>
                                            </div>

                                            <div className='d-flex justify-content-between align-items-center mb-3 font-18'>
                                                <div className='mb-0' style={{ lineHeight: '1'}}>
                                                    <div>Total Tax
                                                        <span style={{ fontWeight: '600', marginLeft: '7px', position: 'relative', top: '-1px' }}>
                                                            <Icon icon={'tabler:circle-letter-d'} width={22} height={22} />
                                                        </span>
                                                    </div>
                                                    <span style={{ fontWeight: '500', fontSize: '14px' }}>
                                                        (<Icon icon={'tabler:circle-letter-d'} width={16} height={16} /> = <Icon icon={'tabler:circle-letter-b'} width={16} height={16} /> + <Icon icon={'tabler:circle-letter-c'} width={16} height={16} />)
                                                    </span>
                                                </div>
                                                <p className='mb-0'>{taxType1 + taxType2}원</p>
                                            </div>
                                        </div>

                                        <hr />

                                        <div className='px-4'>
                                            <div className='d-flex justify-content-between align-items-center mb-0 font-18'>
                                                <div className='mb-0' style={{ lineHeight: '1'}}>
                                                    <div>Final Exchange Amount</div>
                                                    <span style={{ fontWeight: '500', fontSize: '14px' }}>
                                                        (<Icon icon={'tabler:circle-letter-a'} width={16} height={16} /> - <Icon icon={'tabler:circle-letter-d'} width={16} height={16} />)
                                                    </span>
                                                </div>
                                                <p className='mb-0'>{(hearts * 240) - taxType1 - taxType2}원</p>
                                            </div>
                                        </div>
                                    </>
                                )
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default ExchangeDetail;