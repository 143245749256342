import { useEffect, useState } from 'react';
import { constants } from './../Constants';
import { Icon } from '@iconify/react';
import { Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import PosterCard from './PosterCard';
import DeletePopup from '../DeletePopup';
import { useSelector } from 'react-redux';
import useFetch from '../../useFetch';

const PostersIndex = () => {
    document.title = "포스터 관리 | Mowing";
    
    let navigate = useNavigate();
        
    const { user } = useSelector(state => state.user);
    
    useEffect(() => {
        if (!user.token) navigate('/login')
        else if (user.artistName == '-NA-') navigate('/artists/personal-information')
    }, [user])

    const [items, setItems] = useState([]);
    const [deleteItem, setDeleteItem] = useState(null);

    const { response, isLoadingFetch, errorFetch } = useFetch(process.env.REACT_APP_BASE_API_URL+'/poster/all');
    
    useEffect(() => {
        if (response?.posters) {
            setItems(response?.posters ?? [])
        }
    }, [response])

    return (
        <div className="container-fluid artist-details mb-4 pt-2">
            {deleteItem ? <DeletePopup setItems={setItems} deleteItem={deleteItem} setDeleteItem={setDeleteItem} /> : null}
            
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h4 className="heading1 mb-0">포스터 관리</h4>
                <Button as={Link} to={'/artists/posters/create'} variant='tab' size='sm' className='mx-0'>
                    Create a poster
                </Button>
            </div>

            {isLoadingFetch ? (
                <div className='text-center my-3'>
                    <img src='/loader.svg' style={{ width: '80px', height: '80px' }} />
                </div>
            ) : (
                errorFetch ? (
                    <h5 className='text-center text-danger mb-0'>Error loading data, please try again</h5>
                ) : (
                    items.length === 0 ? (
                        <h5 className='text-center py-4 mb-0'>No poster created yet</h5>
                    ) : (
                        <div className='row row-cols-card'>
                            {items.map((item) => (
                                <PosterCard poster={item} setDeleteItem={setDeleteItem} />
                            ))}
                        </div>
                    )
                )
            )}
        </div>
    );
}
 
export default PostersIndex;