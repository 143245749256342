import { useEffect, useRef, useState } from 'react';
import { constants } from './../Constants';
import { Icon } from '@iconify/react';
import { Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { Checkbox, FormControlLabel, TextField } from '@mui/material';
import useFetch from '../../useFetch';
import { useSelector } from 'react-redux';
import { removeUser, setUser, updateUser } from '../../redux/userSlice';
import { store } from '../../redux/store';

const ProfileInfo = () => {
    document.title = "Personal Information | Mowing";
    
    let navigate = useNavigate();
    
    const { user } = useSelector(state => state.user);
    
    useEffect(() => {
        if (!user.token) navigate('/login')
    }, [user])
    
    const fileRef = useRef();
        
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    const [isCreatingProfile, setIsCreatingProfile] = useState(false);

    const [image, setImage] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    
    const [apiUrl, setApiUrl] = useState(process.env.REACT_APP_BASE_API_URL+'/user/profile');
    
    const { response, isLoadingFetch, errorFetch } = useFetch(apiUrl);
    
    useEffect(() => {
        if (response?.user) {
            if (response?.user?.artist?.name === null)
            {
                setIsCreatingProfile(true)
            }

            setName(response?.user?.artist?.name ?? '');
            setDescription(response?.user?.artist?.description ?? '');
            
            store.dispatch(setUser({
                token: user.token,
                name: response?.user?.name,
                artistName: response.user?.artist?.name ?? '-NA-',
                image: response?.user?.imageUrl ? response?.user?.imageUrl : 'default.png',
                type: user.type,
            }));
        }
    }, [response])
    
    const handleImageChange = (e) => {
        let formData = new FormData();

        var file = e.target.files[0];
        setImage(file)
        var reader = new FileReader();
        var url = reader.readAsDataURL(file);

        reader.onloadend = function (e) {
            setImagePreview(reader.result)
        }.bind(this);
    }
    
    const handleSubmit = (e) => {
        console.log('asd')
        e.preventDefault();
        
        setIsLoading(true);
        setSuccess(false);
        setError(false);

        let formData = new FormData();
        formData.append("_method", "PUT");
        formData.append("name", name)
        formData.append("description", description)
        formData.append("image", image)

        fetch(process.env.REACT_APP_BASE_API_URL+'/artist/profile', {
            method: 'PUT',
            headers: { 
                "Accept": "application/json",
                "Authorization": "Bearer "+localStorage.getItem("token")
            },
            body: formData
        })
        .then(response => {
            if (!response.ok) {
                if (response.status == 401)
                {
                    store.dispatch(removeUser());
                    throw Error('Please login to update profile.');
                }
                else
                    throw Error('There was some error while updating profile, please try again.');
            }

            return response.json();
        })
        .then(data => {
            setIsLoading(false);

            if (data.success == true)
            {
                setSuccess('Profile updated successfully');

                if (data?.name) store.dispatch(updateUser({key: 'artistName', value: data.name ?? user.artistName}))
                if (data?.imageUrl) store.dispatch(updateUser({key: 'image', value: data.imageUrl ?? user.image}))

                setImage(null);

                if (fileRef?.current)
                {
                    fileRef.current.value = '';
                }

                if (isCreatingProfile)
                {
                    navigate('/artists/dashboard')
                }
            }
            else if (data.success == false)
            {
                setError(data.message);
            }
            else
            {
                setError('There was some error while updating profile, please try again.');
            }
        })
        .catch(error => {
            setIsLoading(false);
            setError(error.message);
        })
    }

    return (
        <div className="container-fluid artist-details mb-4 pt-2">
            <div className="row justify-content-center flex-fill">
                <div className="col-xl-6 col-lg-7 col-md-8 col-sm-10">
                    <div className='general-card mb-4'>
                        <div className='general-card-header'>
                            <h4 className="heading1 mb-0">Personal Information</h4>
                        </div>

                        <div className="card-body">
                        
                            { user.artistName == '-NA-' && <div className="alert-msg-warning"><h3 className="mb-0"><strong>Please setup your artist profile first.</strong></h3></div> }
                            { error && <div className="alert-msg-danger"><h3 className="mb-0">{ error }</h3></div> }
                            { success && <div className="alert-msg-success"><h3 className="mb-0">{ success }</h3></div> }

                            <label className="custom-file-container custom-file-container-profile">
                                <input 
                                    type="file" 
                                    ref={fileRef}
                                    onChange={handleImageChange}
                                    className="custom-file-container-input" 
                                    accept=".jpeg,.jpg,.png" 
                                    multiple="multiple"/>
                                
                                <span className={`custom-file-container-control d-flex justify-content-center align-items-center`}>
                                    <img src={imagePreview ?? process.env.REACT_APP_BASE_IMAGES_URL+(response?.user?.imageUrl ? response?.user?.imageUrl : 'default.png')} />

                                    <span className='overlay'>
                                        <Icon icon='ic:round-add-a-photo' height={22} width={22} />
                                        <span>Update Photo</span>
                                    </span>
                                </span>
                            </label>
                            
                            <form onSubmit={handleSubmit} className="user">
                                <TextField 
                                    variant="outlined" 
                                    label="Artist name" 
                                    value={name}
                                    onChange={ (e) => setName(e.target.value) }
                                    required={true}
                                    style={{ width: '100%' }}
                                    className="mb-3"
                                    />

                                <TextField 
                                    variant="outlined" 
                                    label="Introduction" 
                                    value={description}
                                    onChange={ (e) => setDescription(e.target.value) }
                                    multiline={true}
                                    rows={3}
                                    style={{ width: '100%' }}
                                    className="mb-3"
                                    />

                                <button 
                                    type="submit" 
                                    disabled={isLoading}
                                    className="btn btn-secondary btn-user btn-block"
                                    >Update Information</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default ProfileInfo;