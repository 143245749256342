import { format, parseISO } from "date-fns";

export const printDate = (date, includeTime = false, monthType = 'short') => {
    if (!date) return ''
    console.log('includeTime', includeTime)
    if (includeTime)
    {
        return format(parseISO(date), 'yyyy-MM-dd HH:mm:ss')
    }
    else
    {
        return format(parseISO(date), 'yyyy-MM-dd')
    }

    date = new Date(date);
    var monthNamesShort = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    var monthNamesLong = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    
    var day = String(date.getDate()).padStart(2, '0');
    var monthIndex = date.getMonth();
    var month = monthType == 'short' ? monthNamesShort[monthIndex] : monthNamesLong[monthIndex];
    var year = date.getFullYear();

    var strTime = '';
    if (includeTime)
    {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0'+minutes : minutes;
        strTime = ", " + hours + ':' + minutes + ' ' + ampm;
    }

    return day + ' ' + month + ' ' + year+strTime
}