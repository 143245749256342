import { useEffect, useRef, useState } from 'react';
import { Checkbox, FormControlLabel, TextField } from '@mui/material';
import { Icon } from '@iconify/react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useFetch from '../../useFetch';
import { store } from '../../redux/store';
import { removeUser } from '../../redux/userSlice';

const PosterEdit = () => {
    document.title = "Edit poster | Mowing";
    
    let navigate = useNavigate();

    const { id } = useParams();
    
    const { user } = useSelector(state => state.user);
    
    useEffect(() => {
        if (!user.token) navigate('/login')
        else if (user.artistName == '-NA-') navigate('/artists/personal-information')
    }, [user])
    
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    
    const fileRef = useRef();

    const maxFiles = 3;

    const [title, setTitle] = useState('')
    const [date, setDate] = useState('')
    const [location, setLocation] = useState('')
    const [fanNotes, setFanNotes] = useState('')
    const [ticketLink, setTicketLink] = useState('')
    const [description, setDescription] = useState('');
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [uploadingFiles, setUploadingFiles] = useState(0);

    const { response, isLoadingFetch, errorFetch } = useFetch(process.env.REACT_APP_BASE_API_URL+'/poster/details/'+id);
    
    useEffect(() => {
        if (response?.poster) {
            setTitle(response?.poster?.title ?? '')
            if (response?.poster?.date) setDate(response?.poster?.date)
            setLocation(response?.poster?.location ?? '')
            setFanNotes(response?.poster?.fanNotes ?? '')
            setTicketLink(response?.poster?.ticketLink ?? '')
            setDescription(response?.poster?.description ?? '')
            setUploadedFiles(response?.poster?.imagesUrls ? response?.poster?.imagesUrls : []);
            setUploadingFiles(0);
        }
    }, [response])
    
    const handleImageChange = (e) => {
        setIsLoading(true);
        setSuccess(false);
        setError(false);

        let formData = new FormData();

        var remainingFiles = maxFiles - uploadedFiles.length - uploadingFiles;
        remainingFiles = Math.min(e.target.files.length, remainingFiles);
        for(var i=0; i<remainingFiles; i++)
        {
            formData.append("images", e.target.files[i]);
        }
        setUploadingFiles(current => current + remainingFiles);
        
        fetch(process.env.REACT_APP_BASE_API_URL+'/poster/images', {
            method: 'POST',
            headers: { 
                "Accept": "application/json",
                "Authorization": "Bearer "+localStorage.getItem("token")
            },
            body: formData
        })
        .then(response => {
            if (!response.ok) {
                if (response.status == 401)
                {
                    store.dispatch(removeUser());
                    throw Error('Please retry after login');
                }
                else
                    throw Error('There was some error while uploading images, please try again.');
            }

            return response.json();
        })
        .then(data => {
            setIsLoading(false);

            if (data.success == true)
            {
                data.imagesUrls.map((image) => {
                    setUploadingFiles(current => current > 1 ? current - 1 : 0);
                    setUploadedFiles(values => ([...values, image]));
                });
            }
            else if (data.success == false)
            {
                setUploadingFiles(0);
                setError(data.message);
            }
            else
            {
                setUploadingFiles(0);
                setError('There was some error while uploading images, please try again.');
            }

            if (fileRef?.current)
            {
                fileRef.current.value = '';
            }
        })
        .catch(error => {
            setIsLoading(false);
            setUploadingFiles(0);
            setError('There was some error while uploading images, please try again.');

            if (fileRef?.current)
            {
                fileRef.current.value = '';
            }
        })
    }

    const handleDeleteImage = (name) => {
        setUploadedFiles(uploadedFiles.filter((file) => {
            return file != name;
        }));
    }
    
    const handleSubmit = (e) => {
        console.log('asd')
        e.preventDefault();
        
        setSuccess(false);
        setError(false);

        if (uploadedFiles.length == 0)
        {
            setError('Upload atleast 1 image');
            return;
        }

        setIsLoading(true);

        fetch(process.env.REACT_APP_BASE_API_URL+'/poster/update/'+id, {
            method: 'PUT',
            headers: { 
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem("token"),
            },
            body: JSON.stringify({
                title,
                date,
                description,
                fanNotes,
                ticketLink,
                location,
                imagesUrls: uploadedFiles,
            })
        })
        .then(response => {
            if (!response.ok) {
                if (response.status == 401)
                {
                    store.dispatch(removeUser());
                    throw Error('Please login to update a poster.');
                }
                else
                    throw Error('There was some error while updating poster, please try again.');
            }

            return response.json();
        })
        .then(data => {
            setIsLoading(false);

            if (data.success == true)
            {
                setSuccess('Poster updated successfully');
            }
            else if (data.success == false)
            {
                setError(data.message);
            }
            else
            {
                setError('There was some error while updating poster, please try again.');
            }
        })
        .catch(error => {
            setIsLoading(false);
            setError(error.message);
        })
    }
    
    return (
        <div className="container-fluid artist-details mb-4 pt-2">
            <div className="row justify-content-center flex-fill">
                <div className="col-xl-6 col-lg-7 col-md-8 col-sm-10">
                    <div className='general-card mb-4'>
                        <div className='general-card-header d-flex justify-content-between'>
                            <h4 className="heading1 mb-0">Edit poster</h4>
                            <Link to={'/artists/posters'} className="go-back mb-0">Go Back</Link>
                        </div>
                        <div className="card-body">
                            { error && <div className="alert-msg-danger"><h3 className="mb-0">{ error }</h3></div> }
                            { success && <div className="alert-msg-success"><h3 className="mb-0">{ success }</h3></div> }

                            <form onSubmit={handleSubmit} className="user">
                                <div className='d-flex align-items-center justify-content-between mb-3'>
                                    <div className='mr-2'>
                                        <label className='mb-1'>Upload Pictures</label>
                                        <p className="mb-0 small">Upload upto {maxFiles} images</p>
                                    </div>
                                    <div>
                                        <label className="custom-file-container">
                                            <input 
                                                type="file" 
                                                ref={fileRef}
                                                onChange={handleImageChange}
                                                disabled={uploadedFiles.length+uploadingFiles>=maxFiles}
                                                className="custom-file-container-input" 
                                                accept="image/*" 
                                                multiple="multiple"/>
                                            
                                            <span className={`custom-file-container-control d-flex justify-content-center align-items-center ${uploadedFiles.length+uploadingFiles>=maxFiles ? 'disabled' : ''}`}>
                                                <Icon icon={'ph:plus-bold'} height={16} width={16} className='d-none d-sm-inline' />
                                                <span className="text-center ml-sm-1">Add Photos</span>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                
                                { uploadedFiles.length > 0 || uploadingFiles > 0 ? (
                                    <div className="row mt-n2 no-gutters mx-n2 row-cols-xl-3 row-cols-lg-3 row-cols-md-3 row-cols-sm-3 row-cols-2 file-previews image-previews">
                                        { uploadedFiles.map((uploadedFile) => (
                                            <div key={uploadedFile} className="col mb-3 px-2 custom-file-container-preview position-relative">
                                                <div className="custom-file-container-preview-item">
                                                    <span onClick={() => handleDeleteImage(uploadedFile)} className="custom-file-container-preview-remove d-flex justify-content-center align-items-center">
                                                        <Icon icon={'ph:x-bold'} />
                                                    </span>
                                                    <img src={process.env.REACT_APP_BASE_IMAGES_URL+uploadedFile} className="img-fluid"/>
                                                </div>
                                            </div>
                                        ))}
                                        
                                        { [...Array(uploadingFiles)].map((uploadingFile, index) => (
                                            <div key={index} className="col mb-3 px-2 custom-file-container-preview custom-file-container-preview-dummy position-relative">
                                                <div className="custom-file-container-preview-item custom-file-container-preview-nonimage" style={{background: '#0E9877'}}>
                                                    <p>Uploading</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ) : null }

                                <TextField 
                                    variant="outlined" 
                                    label="Poster title" 
                                    value={title}
                                    onChange={ (e) => setTitle(e.target.value) }
                                    required={true}
                                    style={{ width: '100%' }}
                                    className="mb-3"
                                    />

                                <TextField 
                                    variant="outlined" 
                                    label="행사일시" 
                                    value={date}
                                    onChange={ (e) => setDate(e.target.value) }
                                    required={true}
                                    style={{ width: '100%' }}
                                    className="mb-3"
                                    />

                                <TextField 
                                    variant="outlined" 
                                    label="행사장소" 
                                    value={location}
                                    onChange={ (e) => setLocation(e.target.value) }
                                    required={true}
                                    style={{ width: '100%' }}
                                    className="mb-3"
                                    />
                                    
                                <TextField 
                                    variant="outlined" 
                                    label="팬들에게 한 마디" 
                                    value={fanNotes}
                                    onChange={ (e) => setFanNotes(e.target.value) }
                                    required={true}
                                    style={{ width: '100%' }}
                                    className="mb-3"
                                    />
                                    
                                <TextField 
                                    variant="outlined" 
                                    label="티켓 예매 링크" 
                                    value={ticketLink}
                                    onChange={ (e) => setTicketLink(e.target.value) }
                                    style={{ width: '100%' }}
                                    className="mb-3"
                                    />
                                    
                                <TextField 
                                    variant="outlined" 
                                    label="Description" 
                                    value={description}
                                    onChange={ (e) => setDescription(e.target.value) }
                                    required={true}
                                    multiline={true}
                                    rows={3}
                                    style={{ width: '100%' }}
                                    className="mb-3"
                                    />

                                <button 
                                    type="submit" 
                                    disabled={isLoading}
                                    className="btn btn-secondary btn-user btn-block"
                                    >Edit Poster</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default PosterEdit;