import { Link, useLocation } from "react-router-dom";

const Footer = () => {
    const currentPath = useLocation().pathname.split('/')[1];

    return (
        <footer className={`footer`}>
            <div className="container-fluid">
                <div className="d-flex align-items-center">
                    <div className="footer-logo">
                        <img id="profile-img" className="profile-img-card" src="/logo.svg" style={{height: '40px'}} />
                    </div>
                    <p className="footer-info">Some text about your company and support help here.</p>
                </div>
                <div>
                    <div className="footer-heading">Support & Contact</div>
                    <div className="d-flex flex-wrap">
                        <p className="footer-link"><Link to="/">Call: +92-333-1234567</Link></p>
                        <p className="footer-link"><Link to="/">Email: info@domain.com</Link></p>
                        <p className="footer-link"><Link to="/">Whatsapp: +92-321-7654321</Link></p>
                        <p className="footer-link"><Link to="/">Terms & Conditions</Link></p>
                        <p className="footer-link"><Link to="/">Privacy Policy</Link></p>
                        <p className="footer-link"><Link to="/">FAQ</Link></p>
                    </div>
                </div>
            </div>
        </footer>
    );
}
 
export default Footer;