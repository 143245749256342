import { useEffect, useState, useRef } from 'react';
import { Accordion, Form } from 'react-bootstrap';
import { Checkbox, FormControlLabel, TextField } from '@mui/material';
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useFetch from '../../useFetch';

const WorkerPassword = () => {
    document.title = "Update Password | Mowing";
    
    const { id } = useParams();
    let navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [validationErrors, setValidationErrors] = useState([]);

    const cardRef = useRef();

    const [password, setPassword] = useState('')
    
    const { response } = useFetch(process.env.REACT_APP_BASE_API_URL+'/workers/'+id);
    
    const handleSubmit = (e) => {
        e.preventDefault();
        
        setIsLoading(true);
        setValidationErrors(false);
        setError(false);
        setSuccess(false);

        let formData = new FormData();
        formData.append("_method", "PUT");
        formData.append("password", password)
        
        fetch(process.env.REACT_APP_BASE_API_URL+'/workers/'+id+'/update-password', {
            method: 'POST',
            headers: { 
                "Accept": "application/json",
                "Authorization": "Bearer "+localStorage.getItem("token")
            },
            body: formData
        })
        .then(response => {
            if (!response.ok) {
                throw Error('There was some error while editing password, please try again.');
            }

            return response.json();
        })
        .then(data => {
            cardRef.current.scrollIntoView();
            setIsLoading(false);

            if (data?.status == 'success')
            {
                setSuccess(data.message);
            }
            else if (data?.status == 'validation_error')
            {
                setValidationErrors(data.messages);
            }
            else if (data?.status == 'error' && data?.message)
            {
                setError(data.message);
            }
            else
            {
                setError('There was some error while editing password, please try again.');
            }
        })
        .catch(error => {
            cardRef.current.scrollIntoView();
            setError(error.message);
            setIsLoading(false);
        })
    }
    
    return (
        <div className="container-fluid">
            <div ref={cardRef} style={{ marginTop: '-80px', position: 'absolute' }}></div>
            <form onSubmit={handleSubmit} className="user">
                <div className="card shadow mb-4">
                    <div className="card-header d-flex">
                        <h5 className="h5 mb-0 text-gray1-800 font-weight-bold text-primary mr-3">Change Worker Password {response?.data?.username ? '(ID: '+response.data.username+')' : ''}</h5>
                        <h6 className="m-0 font-weight-bold text-primary ml-auto align-self-center text-right"><Link onClick={() => navigate(-1)} className="go-back mb-0">Go Back</Link></h6>
                    </div>
                    <div className="card-body">
                        { success && <div className="alert-msg-success"><h3 className="mb-0"><strong>{ success }</strong></h3></div> }
                        
                        { error && <div className="alert-msg-danger"><h3 className="mb-0"><strong>{ error }</strong></h3></div> }

                        { validationErrors.length > 0 &&
                            <div className="alert-msg-danger">
                                <h3 className="mb-1"><strong>There was some error in submitted information, please try again.</strong></h3>
                                <ul className="mb-0" style={{fontSize: '15px'}}>
                                    { validationErrors.map((validationError, index) => (
                                        <li key={index}>{ validationError }</li>
                                    ))}
                                </ul>
                            </div>
                        }

                        <TextField 
                            variant="outlined" 
                            label="New password" 
                            value={password}
                            onChange={ (e) => setPassword(e.target.value) }
                            required={true}
                            style={{ width: '100%' }}
                            className="mb-3"
                            type='password'
                            />

                        <button 
                            type="submit" 
                            disabled={isLoading}
                            className="btn btn-secondary btn-user btn-block"
                            >Update Password</button>
                    </div>
                </div>
            </form>
        </div>
    );
}
 
export default WorkerPassword;