import { useEffect, useRef, useState } from 'react';
import { Checkbox, FormControlLabel, TextField } from '@mui/material';
import { Icon } from '@iconify/react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { store } from '../../redux/store';
import { removeUser } from '../../redux/userSlice';

const PastureCreate = () => {
    document.title = "Add Pasture | Mowing";

    let navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [validationErrors, setValidationErrors] = useState([]);

    const cardRef = useRef();

    const [location, setLocation] = useState('')
    const [address, setAddress] = useState('')
    const [surface, setSurface] = useState('')
    const [comment, setComment] = useState('')
    const [emergencyLevel, setEmergencyLevel] = useState('')

    const handleChange = (e) => {
        const cleanedLocation = e.target.value.replace(/\s/g, '');
        setLocation(cleanedLocation);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        setIsLoading(true);
        setValidationErrors(false);
        setError(false);
        setSuccess(false);

        let formData = new FormData();
        formData.append("address", address)
        formData.append("coordinates", location)
        formData.append("surface", surface)
        formData.append("manager_comments", comment)
        formData.append("emergency_level", emergencyLevel)

        fetch(process.env.REACT_APP_BASE_API_URL + '/pastures', {
            method: 'POST',
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
            body: formData
        })
            .then(response => {
                if (!response.ok) {
                    throw Error('There was some error while adding pasture, please try again.');
                }

                return response.json();
            })
            .then(data => {
                cardRef.current.scrollIntoView();
                setIsLoading(false);

                if (data?.status == 'success') {
                    setSuccess(data.message);

                    setLocation('')
                    setAddress('')
                    setSurface('')
                    setComment('')
                    setEmergencyLevel('')
                }
                else if (data?.status == 'validation_error') {
                    setValidationErrors(data.messages);
                }
                else if (data?.status == 'error' && data?.message) {
                    setError(data.message);
                }
                else {
                    setError('There was some error while adding pasture, please try again.');
                }
            })
            .catch(error => {
                cardRef.current.scrollIntoView();
                setError(error.message);
                setIsLoading(false);
            })
    }

    return (
        <div className="container-fluid">
            <div ref={cardRef} style={{ marginTop: '-80px', position: 'absolute' }}></div>
            <form onSubmit={handleSubmit} className="user">
                <div className="card shadow mb-4">
                    <div className="card-header d-flex">
                        <h5 className="h5 mb-0 text-gray1-800 font-weight-bold text-primary mr-3">Add Pastures</h5>
                        <h6 className="m-0 font-weight-bold text-primary ml-auto align-self-center text-right"><Link onClick={() => navigate(-1)} className="go-back mb-0">Go Back</Link></h6>
                    </div>
                    <div className="card-body">
                        {success && <div className="alert-msg-success"><h3 className="mb-0"><strong>{success}</strong></h3></div>}

                        {error && <div className="alert-msg-danger"><h3 className="mb-0"><strong>{error}</strong></h3></div>}

                        {validationErrors.length > 0 &&
                            <div className="alert-msg-danger">
                                <h3 className="mb-1"><strong>There was some error in submitted information, please try again.</strong></h3>
                                <ul className="mb-0" style={{ fontSize: '15px' }}>
                                    {validationErrors.map((validationError, index) => (
                                        <li key={index}>{validationError}</li>
                                    ))}
                                </ul>
                            </div>
                        }

                        <TextField
                            variant="outlined"
                            label="Pasture location"
                            value={location}
                            onChange={(e) => handleChange(e)}
                            required={true}
                            style={{ width: '100%' }}
                            className="mb-3"
                        />

                        <TextField
                            variant="outlined"
                            label="Pasture address"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                            required={true}
                            style={{ width: '100%' }}
                            className="mb-3"
                        />

                        <TextField
                            variant="outlined"
                            label="Pasture surface m2"
                            value={surface}
                            onChange={(e) => setSurface(e.target.value)}
                            required={true}
                            style={{ width: '100%' }}
                            className="mb-3"
                        />

                        <TextField
                            variant="outlined"
                            label="Pasture comment (manager)"
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            style={{ width: '100%' }}
                            className="mb-3"
                        />

                        <TextField
                            variant="outlined"
                            label="Emergency level"
                            value={emergencyLevel}
                            onChange={(e) => setEmergencyLevel(e.target.value)}
                            required={true}
                            style={{ width: '100%' }}
                            className="mb-3"
                        />

                        <button
                            type="submit"
                            disabled={isLoading}
                            className="btn btn-secondary btn-user btn-block"
                        >Add Pasture</button>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default PastureCreate;