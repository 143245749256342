import { useEffect, useState, useRef } from 'react';
import { Form } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";

const NotFound = () => {
    document.title = "Not Found | Mowing";
    
    return (
        <div className="container-fluid">
            <div className="text-center">
                <h2 className='text-danger py-3'>Page not found.</h2>
            </div>
        </div>
    );
}
 
export default NotFound;