import { useEffect, useRef, useState } from 'react';
import { Checkbox, FormControlLabel, TextField } from '@mui/material';
import { Icon } from '@iconify/react';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { store } from '../../redux/store';
import { removeUser } from '../../redux/userSlice';
import { useParams } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import useFetch from '../../useFetch';



const FinishWork = () => {
    document.title = "Finish Work | Mowing";

    let navigate = useNavigate();

    const { user } = useSelector(state => state.user);

    useEffect(() => {
        if (!user.token) navigate('/login')
        else if (user.artistName == '-NA-') navigate('/artists/personal-information')
    }, [user])

    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [validationErrors, setValidationErrors] = useState([]);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [imagePreviews, setImagePreviews] = useState([]);




    const [completionStatus, setCompletionStatus] = useState(null)
    const [isProblem, setIsProblem] = useState(null)
    const [comments, setComments] = useState('')
    const { pastureId } = useParams();

    const [isStartWorkDisabled, setStartWorkDisabled] = useState(false);
    const [isFinishWorkDisabled, setFinishWorkDisabled] = useState(true);


    const cardRef = useRef();

    const maxFiles = 5;
    const fileRef = useRef();
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [uploadingFiles, setUploadingFiles] = useState(0);
    const [cover, setCover] = useState('');
    const { refetch: refetchPastureData, response, isLoadingFetch, errorFetch } = useFetch(
        `${process.env.REACT_APP_BASE_API_URL}/pastures/${pastureId}`
    );

    const fetchPastureData = async () => {
        try {
            const updatedResponse = await fetch(`${process.env.REACT_APP_BASE_API_URL}/pastures/${pastureId}`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/json',
                },
            });
            if (!updatedResponse.ok) {
                console.error('Failed to fetch updated data:', updatedResponse.statusText);
                return;
            }

            const updatedData = await updatedResponse.json();
            // Update your state with the updated data
            // setResponse(updatedData);
        } catch (error) {
            console.error('Error while fetching updated data:', error.message);
        }
    };

    const formattedDate = format(selectedDate, 'yyyy-MM-dd');
    const handleStartWork = async (pastureId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/pastures/${pastureId}/start-work`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                console.error('Failed to start work:', response.statusText);
                return;
            }
            fetchPastureData();
            const updatedTask = await response.json();


        } catch (error) {
            console.error('Error while starting work:', error.message);
        }
    };


    const handleFileChange = (e) => {

        const files = e.target.files;
        setUploadedFiles([...files]);

        // Create an array to store image URLs
        const previews = [];

        // Use FileReader to read each selected file
        for (let i = 0; i < files.length; i++) {
            const reader = new FileReader();

            reader.onload = (event) => {
                previews.push(event.target.result);
                // Update state with image URLs
                setImagePreviews([...previews]);
            };

            // Read the selected file as Data URL
            reader.readAsDataURL(files[i]);
        }
    };


    const handleSubmit = (e) => {
        e.preventDefault();

        setSuccess(false);
        setError(false);

        if (completionStatus === null) {
            setError('Please select completion status');
            return;
        }
        if (isProblem === null) {
            setError('Please select problem status');
            return;
        }

        setIsLoading(true);

        let formData = new FormData();
        formData.append("worker_comments", comments);
        formData.append("is_problem", isProblem ? 1 : 0);
        formData.append("completion_status", completionStatus);

        // Append each selected file to the formData
        for (let i = 0; i < uploadedFiles.length; i++) {
            formData.append(`images[]`, uploadedFiles[i]); // Use square brackets for array-like submission
        }

        fetch(`${process.env.REACT_APP_BASE_API_URL}/pastures/${pastureId}/update-worker`, {
            method: 'POST',
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token"),
            },
            body: formData
        })
            .then(response => {
                if (response.ok) {
                    setSuccess('Successfully updated pasture.');
                    // Any other logic or state updates you want to perform on success
                } else {
                    // Handle the case when the response is not OK
                    console.error('Failed to update pasture:', response.statusText);
                    setError('There was an error while updating pasture. Please try again.');
                }
            })
            .catch(error => {
                setIsLoading(false);
                setError(error.message);
            });
    };

    useEffect(() => {
        if (completionStatus !== null && error == 'Please select completion status')
            setError(false);
    }, [completionStatus])

    useEffect(() => {
        if (isProblem !== null && error == 'Please select problem status')
            setError(false);
    }, [isProblem])


    return (
        <div className="container-fluid artist-details mb-4 pt-2">
            <div className="row justify-content-center flex-fill">
                <div className="col-xl-6 col-lg-7 col-md-8 col-sm-10">
                    <div className='general-card mb-4'>
                        <div className='general-card-header d-flex justify-content-between'>
                            <h4 className="heading1 mb-0">Update Pasture  </h4>
                            <Link to={'/workers/dashboard'} className="go-back mb-0">Go Back</Link>
                        </div>
                        <div className="card-body">
                            <div >
                                {/* <Button
                                    onClick={() => handleStartWork(pastureId)}
                                    className="btn btn-secondary btn-user btn-block"
                                    variant='primary'
                                    disabled={isStartWorkDisabled}
                                >
                                    Start Work
                                </Button> */}
                            </div>

                            {error && <div className="alert-msg-danger"><h3 className="mb-0">{error}</h3></div>}
                            {success && <div className="alert-msg-success"><h3 className="mb-0">{success}</h3></div>}

                            <form onSubmit={handleSubmit} className="user">
                                <div className='mb-3'>
                                    <label className='mb-1'>Job Completion Status</label>
                                    <div className='d-flex'>
                                        <p onClick={() => setCompletionStatus(0)} className={`flex-fill badge-checkbox px-2 ${completionStatus === 0 ? 'primary' : 'light'}`}>0%</p>
                                        <p onClick={() => setCompletionStatus(25)} className={`flex-fill badge-checkbox px-2 ${completionStatus === 25 ? 'primary' : 'light'}`}>25%</p>
                                        <p onClick={() => setCompletionStatus(50)} className={`flex-fill badge-checkbox px-2 ${completionStatus === 50 ? 'primary' : 'light'}`}>50%</p>
                                        <p onClick={() => setCompletionStatus(75)} className={`flex-fill badge-checkbox px-2 ${completionStatus === 75 ? 'primary' : 'light'}`}>75%</p>
                                        <p onClick={() => setCompletionStatus(100)} className={`flex-fill badge-checkbox px-2 mr-0 ${completionStatus === 100 ? 'primary' : 'light'}`}>100%</p>
                                    </div>
                                </div>

                                <div className='mb-3'>
                                    <label className='mb-1'>Is there any problem?</label>
                                    <div>
                                        <p onClick={() => setIsProblem(false)} className={`badge-checkbox ${isProblem === false ? 'success' : 'light'}`}>No</p>
                                        <p onClick={() => setIsProblem(true)} className={`badge-checkbox ${isProblem === true ? 'danger' : 'light'}`}>Yes</p>
                                    </div>
                                </div>

                                <TextField
                                    variant="outlined"
                                    label="Comments"
                                    value={comments}
                                    onChange={(e) => setComments(e.target.value)}
                                    multiline={true}
                                    rows={3}
                                    style={{ width: '100%' }}
                                    className="mb-3"
                                />

                                <input
                                    type="file"
                                    accept="image/*"
                                    ref={fileRef}
                                    onChange={(e) => handleFileChange(e)}
                                    multiple
                                />
                                <div className="mb-3">
                                    <label className="mb-1">Selected Images</label>
                                    <div className="d-flex">
                                        {imagePreviews.map((preview, index) => (
                                            <img
                                                key={index}
                                                src={preview}
                                                alt={`Preview-${index}`}
                                                style={{ width: '100px', height: '100px', marginRight: '10px', objectFit: 'cover' }}
                                            />
                                        ))}
                                    </div>
                                </div>


                                <button
                                    type="submit"
                                    // disabled={isFinishWorkDisabled || isLoading}
                                    className="btn btn-secondary btn-user btn-block"
                                >
                                    Update Pasture
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FinishWork;