import { useEffect, useState } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import '../assets/css/sb-admin-2.min.css';
import '../assets/css/styles.css';

import Login from "./Login";
import NotFound from "./NotFound";

import WorkerDashboard from "./Workers/Dashboard";
import WorkerFinishWork from "./Workers/FinishWork";

import ArtistDashboard from "./Artists/Dashboard";

import WorkerIndex from "./Artists/WorkerIndex";
import WorkerCreate from "./Artists/WorkerCreate";
import WorkerEdit from "./Artists/WorkerEdit";

import ArtistExchangeHistory from "./Artists/ExchangeHistory";
import ArtistExchangeDetail from "./Artists/ExchangeDetail";
import ArtistPostersIndex from "./Artists/PostersIndex";
import ArtistPostersCreate from "./Artists/PostersCreate";
import ArtistPostersEdit from "./Artists/PostersEdit";
import ArtistProfileInfo from "./Artists/ProfileInfo";
import ArtistRegisterInfo from "./Artists/RegisterInfo";

import WorkerNavbar from "./WorkerNavbar";
import AdminNavbar from "./AdminNavbar";
//import ArtistSidebar from "./ArtistSidebar";
import Unauthorized from "./Unauthorized";
import { useSelector } from "react-redux";
import Faqs from "./Faqs";
import TermsUse from "./TermsUse";
import TermsConditions from "./TermsConditions";
import RefundPolicy from "./RefundPolicy";
import Footer from "./Footer";
import TermsExchange from "./TermsExchange";
import WorkerPassword from "./Artists/WorkerPassword";
import PastureIndex from "./Artists/PastureIndex";
import PastureCreate from "./Artists/PastureCreate";
import PastureEdit from "./Artists/PastureEdit";
import Tasks from "./Workers/Tasks";

const App = () => {
    const { user } = useSelector(state => state.user);

    return ( 
        <>
            <Routes>
                <Route path="/" element={<Login />} />
                
                {user?.type == 'worker' ? (
                    <Route element={<AppLayout user={user} panel='worker' />} >
                        <Route path="/workers/dashboard" element={<WorkerDashboard />} />
                        <Route path="/workers/update-pasture/:pastureId" element={<WorkerFinishWork />} />
                        
                        <Route path="unauthorized" element={<Unauthorized />} />

                        <Route path="*" element={<NotFound />} />
                    </Route>
                ) : null }
                
                {user?.type == 'admin' ? (
                    <Route element={<AppLayout user={user} panel='admin' />} >
                        <Route path="/admin/dashboard" element={<ArtistDashboard />} />
                        <Route path="/admin/tasks" element={<Tasks />} />
                        
                        <Route path="/admin/pastures" element={user?.type == 'admin' ? <PastureIndex /> : <Unauthorized />} />
                        <Route path="/admin/pastures/create" element={user?.type == 'admin' ? <PastureCreate /> : <Unauthorized />} />
                        <Route path="/admin/pastures/edit/:id" element={user?.type == 'admin' ? <PastureEdit /> : <Unauthorized />} />
                        
                        <Route path="/admin/workers" element={user?.type == 'admin' ? <WorkerIndex /> : <Unauthorized />} />
                        <Route path="/admin/workers/create" element={user?.type == 'admin' ? <WorkerCreate /> : <Unauthorized />} />
                        {/* <Route path="/admin/workers/detail/:id" element={user?.type == 'admin' ? <ArtistEditWorker /> : <Unauthorized />} /> */}
                        <Route path="/admin/workers/edit/:id" element={user?.type == 'admin' ? <WorkerEdit /> : <Unauthorized />} />
                        <Route path="/admin/workers/password/:id" element={user?.type == 'admin' ? <WorkerPassword /> : <Unauthorized />} />
                    
                        <Route path="/artists/exchange" element={<ArtistExchangeHistory />} />
                        <Route path="/artists/exchange/:id" element={<ArtistExchangeDetail />} />
                        <Route path="/artists/posters" element={<ArtistPostersIndex />} />
                        <Route path="/artists/posters/create" element={<ArtistPostersCreate />} />
                        <Route path="/artists/posters/edit/:id" element={<ArtistPostersEdit />} />
                        <Route path="/artists/personal-information" element={<ArtistProfileInfo />} />
                        <Route path="/artists/register-information" element={<ArtistRegisterInfo />} />
                        <Route path="/artists/terms-exchange" element={<TermsExchange />} />
                        
                        <Route path="unauthorized" element={<Unauthorized />} />

                        <Route path="*" element={<NotFound />} />
                    </Route>
                ) : null }
            </Routes>

            <Footer />
        </>
    );
}

const AppLayout = ({user, panel}) => {
    const [sidebarToggled, setSidebarToggled] = useState(false);

    return (
        <div className={`admin-app ${sidebarToggled ? 'app-sidebar-toggled' : ''}`} id="wrapper">
            <div id="content-wrapper" className="d-flex flex-column" onClick={() => { if (sidebarToggled) setSidebarToggled(false) }}>
                <div id="content">
                    {panel == 'worker' ? (
                        <WorkerNavbar />
                    ) : null }

                    {panel == 'admin' ? (
                        <AdminNavbar 
                            // sidebarToggled={sidebarToggled}
                            // setSidebarToggled={setSidebarToggled}
                            />
                    ) : null }
                        
                    <div style={{ marginTop: '80px' }}>
                        <Outlet />
                    </div>
                </div>
            </div>

            {/* {panel == 'artist' ? (
                <ArtistSidebar 
                    setSidebarToggled={setSidebarToggled} 
                    user={user}  />
            ) : null } */}
        </div>
    )
};
 
export default App;