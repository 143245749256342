import { useEffect, useState, useRef } from 'react';
import { Accordion, Form } from 'react-bootstrap';
import { Checkbox, FormControlLabel, TextField } from '@mui/material';
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useFetch from '../../useFetch';

const WorkerEdit = () => {
    document.title = "Update Worker | Mowing";
    
    const { id } = useParams();
    let navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [validationErrors, setValidationErrors] = useState([]);

    const cardRef = useRef();

    const [name, setName] = useState('')
    const [dailyWork, setDailyWork] = useState('')
    const [startPoint, setStartPoint] = useState('')
    const [mowRate, setMowRate] = useState('')
    const [maxEmergencyDistance, setMaxEmergencyDistance] = useState('')
    const [comment, setComment] = useState('')
    const [username, setUsername] = useState('')
    
    
    const { response } = useFetch(process.env.REACT_APP_BASE_API_URL+'/workers/'+id);
    useEffect(() => {
        if (response?.data)
        {
            if (response.data.name) setName(response.data.name);
            if (response.data.daily_work_hours) setDailyWork(response.data.daily_work_hours);
            if (response.data.start_coordinates) setStartPoint(response.data.start_coordinates);
            if (response.data.mow_rate) setMowRate(response.data.mow_rate);
            if (response.data.max_emergency_distance) setMaxEmergencyDistance(response.data.max_emergency_distance);
            if (response.data.manager_comments) setComment(response.data.manager_comments);
            if (response.data.username) setUsername(response.data.username);
        }
    }, [response])

    const handleSubmit = (e) => {
        e.preventDefault();
        
        setIsLoading(true);
        setValidationErrors(false);
        setError(false);
        setSuccess(false);

        let formData = new FormData();
        formData.append("_method", "PUT");
        formData.append("name", name)
        formData.append("daily_work_hours", dailyWork)
        formData.append("start_coordinates", startPoint)
        formData.append("mow_rate", mowRate)
        formData.append("max_emergency_distance", maxEmergencyDistance)
        formData.append("manager_comments", comment)
        formData.append("username", username)
        
        fetch(process.env.REACT_APP_BASE_API_URL+'/workers/'+id, {
            method: 'POST',
            headers: { 
                "Accept": "application/json",
                "Authorization": "Bearer "+localStorage.getItem("token")
            },
            body: formData
        })
        .then(response => {
            if (!response.ok) {
                throw Error('There was some error while editing worker, please try again.');
            }

            return response.json();
        })
        .then(data => {
            cardRef.current.scrollIntoView();
            setIsLoading(false);

            if (data?.status == 'success')
            {
                setSuccess(data.message);
            }
            else if (data?.status == 'validation_error')
            {
                setValidationErrors(data.messages);
            }
            else if (data?.status == 'error' && data?.message)
            {
                setError(data.message);
            }
            else
            {
                setError('There was some error while editing worker, please try again.');
            }
        })
        .catch(error => {
            cardRef.current.scrollIntoView();
            setError(error.message);
            setIsLoading(false);
        })
    }
    
    return (
        <div className="container-fluid">
            <div ref={cardRef} style={{ marginTop: '-80px', position: 'absolute' }}></div>
            <form onSubmit={handleSubmit} className="user">
                <div className="card shadow mb-4">
                    <div className="card-header d-flex">
                        <h5 className="h5 mb-0 text-gray1-800 font-weight-bold text-primary mr-3">Modify Worker {response?.data?.username ? '(ID: '+response.data.username+')' : ''}</h5>
                        <h6 className="m-0 font-weight-bold text-primary ml-auto align-self-center text-right"><Link onClick={() => navigate(-1)} className="go-back mb-0">Go Back</Link></h6>
                    </div>
                    <div className="card-body">
                        { success && <div className="alert-msg-success"><h3 className="mb-0"><strong>{ success }</strong></h3></div> }
                        
                        { error && <div className="alert-msg-danger"><h3 className="mb-0"><strong>{ error }</strong></h3></div> }

                        { validationErrors.length > 0 &&
                            <div className="alert-msg-danger">
                                <h3 className="mb-1"><strong>There was some error in submitted information, please try again.</strong></h3>
                                <ul className="mb-0" style={{fontSize: '15px'}}>
                                    { validationErrors.map((validationError, index) => (
                                        <li key={index}>{ validationError }</li>
                                    ))}
                                </ul>
                            </div>
                        }

                        <TextField 
                            variant="outlined" 
                            label="Worker name" 
                            value={name}
                            onChange={ (e) => setName(e.target.value) }
                            required={true}
                            style={{ width: '100%' }}
                            className="mb-3"
                            />
                            
                        <TextField 
                            variant="outlined" 
                            label="Worker daily work hours" 
                            value={dailyWork}
                            onChange={ (e) => setDailyWork(e.target.value) }
                            required={true}
                            style={{ width: '100%' }}
                            className="mb-3"
                            />
                            
                        <TextField 
                            variant="outlined" 
                            label="Worker start point" 
                            value={startPoint}
                            onChange={ (e) => setStartPoint(e.target.value) }
                            required={true}
                            style={{ width: '100%' }}
                            className="mb-3"
                            />
                            
                        <TextField 
                            variant="outlined" 
                            label="Worker mow rate (m2/h)" 
                            value={mowRate}
                            onChange={ (e) => setMowRate(e.target.value) }
                            required={true}
                            style={{ width: '100%' }}
                            className="mb-3"
                            />
                            
                        <TextField 
                            variant="outlined" 
                            label="Max emergency distance (km)" 
                            value={maxEmergencyDistance}
                            onChange={ (e) => setMaxEmergencyDistance(e.target.value) }
                            required={true}
                            style={{ width: '100%' }}
                            className="mb-3"
                            />

                        <TextField 
                            variant="outlined" 
                            label="Pasture comment (manager)" 
                            value={comment}
                            onChange={ (e) => setComment(e.target.value) }
                            style={{ width: '100%' }}
                            className="mb-3"
                            />

                        <TextField 
                            variant="outlined" 
                            label="Login username" 
                            value={username}
                            onChange={ (e) => setUsername(e.target.value) }
                            required={true}
                            style={{ width: '100%' }}
                            className="mb-3"
                            />

                        <button 
                            type="submit" 
                            disabled={isLoading}
                            className="btn btn-secondary btn-user btn-block"
                            >Save Changes</button>
                    </div>
                </div>
            </form>
        </div>
    );
}
 
export default WorkerEdit;