import { useEffect, useState } from 'react';
import { constants } from './../Constants';
import { Icon } from '@iconify/react';
import { Button, Table, Alert } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useFetch from '../../useFetch';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';


const Tasks = () => {
    document.title = "Dashboard | Mowing";

    let navigate = useNavigate();

    const { user } = useSelector(state => state.user);

    const [selectedDate, setSelectedDate] = useState(new Date());

    useEffect(() => {
        const currentDate = new Date();
        setSelectedDate(currentDate);
    }, []);

    const formattedDate = format(selectedDate, 'yyyy-MM-dd');

    const { response, isLoadingFetch, errorFetch } = useFetch(
        `${process.env.REACT_APP_BASE_API_URL}/display-results?date=${formattedDate}`
    );


    const convertMinutesToHours = (minutes) => {
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;

        if (hours > 0 && remainingMinutes > 0) {
            return `${hours} hours ${remainingMinutes} minutes`;
        } else if (hours > 0) {
            return `${hours} hours`;
        } else {
            return `${remainingMinutes} minutes`;
        }
    };


    const [assigningTask, setAssigningTask] = useState(false);
    const [assignTaskSuccess, setAssignTaskSuccess] = useState(false);

   

    return (
        <div className="container-fluid artist-details mb-4 py-2">
            <div className='mb-3'>
                <h6 className="title">Tasks</h6>

                <div className='mt-1 mb-3'>
                    <Button as={Link} to="/admin/pastures/create" className="btn-sm mb-1 mr-2" variant='primary'>Add a pasture</Button>
                    <Button as={Link} to="/admin/workers/create" className="btn-sm mb-1 mr-2" variant='primary'>Add a worker</Button>
                    <Button as={Link} to="/admin/pastures" className="btn-sm mb-1 mr-2" variant='info'>View all pastures</Button>
                    <Button as={Link} to="/admin/workers" className="btn-sm mb-1 mr-2" variant='info'>View all workers</Button>
                   
                </div>

                <div className="card shadow mb-4">
                    <div className="card-header d-flex">
                        <h5 className="h5 mb-0 text-gray1-800 font-weight-bold text-primary mr-3">Task</h5>
                        <h6 className="m-0 font-weight-bold text-primary ml-auto align-self-center text-right"><Link to="/admin/workers">View All</Link></h6>
                    </div>
                    <div className="card-body">
                        <p><strong>Date:</strong>   <DatePicker
                            selected={selectedDate}
                            onChange={date => setSelectedDate(date)}
                        /></p>
                        <Table responsive bordered hover>
                            <thead>
                                <tr>
                                    <th>Task Date</th>
                                    <th>User Name</th>
                                    <th>Pasture Address</th>
                                    <th>Pasture Coordinates</th>
                                    <th>Mowing Time</th>
                                    <th>Traveling Time</th>
                                    <th>Moving From Home</th>
                                    <th>Status</th>
                                    <th>Sequence</th>
                                </tr>
                            </thead>
                            <tbody>
                                {response?.results?.map((task) => (
                                    <tr key={task.id}>
                                        <td>{task.task_date}</td>
                                        <td>{task.user?.name}</td>
                                        <td>{task.pasture?.address}</td>
                                        <td>
                                            <a
                                                href={`https://www.google.com/maps/search/?api=1&query=${task.pasture?.coordinates}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {task.pasture?.coordinates}
                                            </a>
                                        </td>
                                        <td>{`${task.mowing_time} mins (${convertMinutesToHours(task.mowing_time)})`}</td>
                                        <td>{`${task.traveling_time} mins (${convertMinutesToHours(task.traveling_time)})`}</td>
                                        <td>{task.moving_from_home === 1 ? "Yes" : "No"}</td>
                                        <td>{task.pasture.completion_status}</td>
                                        <td>{task.priority}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Tasks;