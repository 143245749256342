import { useEffect, useState } from 'react';
import { constants } from './../Constants';
import { Icon } from '@iconify/react';
import { Button, Table, Alert } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useFetch from '../../useFetch';

const Dashboard = () => {
    document.title = "Dashboard | Mowing";

    let navigate = useNavigate();

    const { user } = useSelector(state => state.user);

    const { response, isLoadingFetch, errorFetch } = useFetch(process.env.REACT_APP_BASE_API_URL + '/dashboard');

    const convertMinutesToHours = (minutes) => {
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;

        if (hours > 0 && remainingMinutes > 0) {
            return `${hours} hours ${remainingMinutes} minutes`;
        } else if (hours > 0) {
            return `${hours} hours`;
        } else {
            return `${remainingMinutes} minutes`;
        }
    };


    const [assigningTask, setAssigningTask] = useState(false);
    const [assignTaskSuccess, setAssignTaskSuccess] = useState(false);


    const handleButtonClick = async () => {
        try {
            setAssigningTask(true);
            setAssignTaskSuccess(false);

            const response = await fetch('https://client74.webdroidsolutions.com/mowing-api/public/calculate', {
            // const response = await fetch('http://localhost/mowing-api/public/calculate', {

                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }

            const data = await response.json();
            console.log('Data:', data);

            // Assuming data.success is a flag indicating success
            if (data.success) {
                setAssignTaskSuccess(true);
            }
        } catch (error) {
            console.error('Error:', error.message);
            // Handle errors
        } finally {
            setAssigningTask(false);
        }
    };


    return (
        <div className="container-fluid artist-details mb-4 py-2">
            <div className='mb-3'>
                <h6 className="title">Welcome {user.name}!</h6>

                <div className='mt-1 mb-3'>
                    <Button as={Link} to="/admin/pastures/create" className="btn-sm mb-1 mr-2" variant='primary'>Add a pasture</Button>
                    <Button as={Link} to="/admin/workers/create" className="btn-sm mb-1 mr-2" variant='primary'>Add a worker</Button>
                    <Button as={Link} to="/admin/pastures" className="btn-sm mb-1 mr-2" variant='info'>View all pastures</Button>
                    <Button as={Link} to="/admin/workers" className="btn-sm mb-1 mr-2" variant='info'>View all workers</Button>
                    {/* <button className='btn-success btn-sm' onClick={handleButtonClick} disabled={assigningTask}>
                        {assigningTask ? 'Assigning Task...' : 'Assign Task Manually'}
                    </button>
                    {assignTaskSuccess && (
                        <Alert variant="success" className="mt-2">
                            Task assigned successfully!
                        </Alert>
                    )} */}

                </div>

                <div className="card shadow mb-4">
                    <div className="card-header d-flex">
                        <h5 className="h5 mb-0 text-gray1-800 font-weight-bold text-primary mr-3">Recent Workers</h5>
                        <h6 className="m-0 font-weight-bold text-primary ml-auto align-self-center text-right"><Link to="/admin/workers">View All</Link></h6>
                    </div>
                    <div className="card-body">
                        <Table responsive bordered hover>
                            <thead>
                                <tr>
                                    <th style={{ minWidth: '180px', width: '180px' }}>ID</th>
                                    <th style={{ minWidth: '180px' }}>Name</th>
                                    <th style={{ minWidth: '120px' }}>Working Hours</th>
                                    <th style={{ minWidth: '120px' }}>Mow Rate</th>
                                </tr>
                            </thead>
                            <tbody>
                                {response?.workers?.map((item) => (
                                    <tr key={item.id}>
                                        <td>{item.id}</td>
                                        <td>{item.name}</td>
                                        <td>{item.daily_work_hours} hours</td>
                                        <td>{item.mow_rate}m<sup>2</sup></td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </div>

                <div className="card shadow mb-4">
                    <div className="card-header d-flex">
                        <h5 className="h5 mb-0 text-gray1-800 font-weight-bold text-primary mr-3">Recent Pastures</h5>
                        <h6 className="m-0 font-weight-bold text-primary ml-auto align-self-center text-right"><Link to="/admin/pastures">View All</Link></h6>
                    </div>
                    <div className="card-body">
                        {response?.totalPastures ? <p className='mb-3'>Total number: <strong>{response?.totalPastures}</strong> - Total surface: <strong>{response?.totalPasturesSurface}m<sup>2</sup></strong></p> : null}
                        <Table responsive bordered hover>
                            <thead>
                                <tr>
                                    <th style={{ width: '50px' }}>ID</th>
                                    <th style={{ minWidth: '100%' }}>Pasture</th>
                                </tr>
                            </thead>
                            <tbody>
                                {response?.pastures?.map((item) => (
                                    <tr key={item.id} style={{ backgroundColor: item.is_problem ? '#f7eaea' : 'none' }}>
                                        <td>{item.id}</td>
                                        <td style={{ minWidth: '100%' }}>
                                            {item.is_problem ? <p className='badge badge-danger mb-2'><strong>HAS PROBLEM</strong></p> : null}
                                            <p className='mb-0'><strong>{item.address}</strong> ({item.coordinates}) <a href={`https://www.google.com/maps?q=${item.coordinates}`} target='_blank'>View on Google Map</a></p>
                                            <p className='mb-0'>Surface: <strong>{item.surface}m<sup>2</sup></strong></p>
                                            <p className='mb-0'>Completed: <strong>{item.completion_status}%</strong></p>
                                            <p className='mb-0'>Last Mowed: <strong>{item.last_mowing_date}</strong></p>
                                            <p className='mb-0'>Emergency Level: <strong>{item.emergency_level}</strong></p>
                                            {item.manager_comments ? <p className='mb-0'>Comment: {item.manager_comments}</p> : null}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;