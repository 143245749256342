import { TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { store } from "../redux/store";
import { setUser } from "../redux/userSlice";

const Login = () => {
    document.title = "Login | Mowing";

    document.body.classList.add('bg-white')
    
    let navigate = useNavigate();
    const { user } = useSelector(state => state.user);

    useEffect(() => {
        if (user.token)
        {
            navigate(user.type == 'worker' ? '/workers/dashboard' : '/admin/dashboard')
        }
    }, [user])
    
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [validationErrors, setValidationErrors] = useState([]);
    
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        
        setIsLoading(true);
        setValidationErrors(false);
        setError(false);
        setSuccess(false);

        let formData = new FormData();
        formData.append("username", username);
        formData.append("password", password);
        
        fetch(process.env.REACT_APP_BASE_API_URL+'/login', {
            method: 'POST',
            headers: { 
                "Accept": "application/json",
            },
            body: formData
        })
        .then(response => {
            if (!response.ok) {
                throw Error('There was some error while logging in, please try again.');
            }

            return response.json();
        })
        .then(data => {
            setIsLoading(false);

            if (data?.status == 'success')
            {
                store.dispatch(setUser({
                    token: data.access_token,
                    name: data.user.name,
                    username: data.user.username,
                    type: data.user.type,
                }));

                navigate('/dashboard')
            }
            else if (data?.status == 'validation_error')
            {
                setValidationErrors(data.messages);
            }
            else if (data?.status == 'error' && data?.message)
            {
                setError(data.message);
            }
            else
            {
                setError('There was some error while logging in, please try again.');
            }
        })
        .catch(error => {
            setIsLoading(false);
            setError(error.message);
        })
    }

    if (user.token) return null

    return (
        <div className="container-fluid d-lg-flex" style={{ minHeight: '100vh' }}>
            <div className="row justify-content-center">
                <div className="col-xl-4 col-lg-5 col-md-12 d-none d-lg-flex">
                    <div className="card o-hidden border-0 shadow my-3 flex-fill">
                        <div className="card-body px-0 pt-3 pb-1 d-flex flex-column justify-content-center h-100">
                            <div className="px-4 mb-4">
                                <Link to={"/"}>
                                    <img className="profile-img-card mt-3" src="/logo.svg" style={{height: '30px'}} />
                                </Link>
                                <h1 className="login-h1 mb-3 mt-3">Hi, Welcome Back</h1>
                            </div>
                            <img className="profile-img-card" src="/illustration_login.png" style={{width: '100%'}} />
                        </div>
                    </div>
                </div>
                
                <div className="col-xl-8 col-lg-7 col-md-12 d-flex">
                    <div className="row justify-content-center flex-fill">
                        <div className="col-xl-8 col-lg-10 col-md-9 col-sm-11">
                            <div className="card-body p-3 d-flex flex-column justify-content-center h-100">
                                <Link to={"/"} className="mt-3 mb-3 d-lg-none">
                                    <img className="profile-img-card mt-3" src="/logo.svg" style={{height: '30px'}} />
                                </Link>
                                <h4 className="login-h4 mb-3">Sign in to Website</h4>

                                { success && <div className="alert-msg-success"><h3 className="mb-0"><strong>{ success }</strong></h3></div> }

                                { error && <div className="alert-msg-danger"><h3 className="mb-0"><strong>{ error }</strong></h3></div> }

                                { validationErrors.length > 0 &&
                                    <div className="alert-msg-danger">
                                        <h3 className="mb-1"><strong>There was some error in submitted information, please try again.</strong></h3>
                                        <ul className="mb-0" style={{fontSize: '15px'}}>
                                            { validationErrors.map((validationError, index) => (
                                                <li key={index}>{ validationError }</li>
                                            ))}
                                        </ul>
                                    </div>
                                }
                        
                                <form className="user" onSubmit={handleSubmit}>
                                    <TextField 
                                        variant="outlined" 
                                        label="Username" 
                                        value={username}
                                        name="username"
                                        onChange={ (e) => setUsername(e.target.value) }
                                        required={true}
                                        style={{ width: '100%' }}
                                        className="mb-3"
                                        />
                                        
                                    <TextField 
                                        variant="outlined" 
                                        type={"password"}
                                        label="Password" 
                                        value={password}
                                        onChange={ (e) => setPassword(e.target.value) }
                                        required={true}
                                        style={{ width: '100%' }}
                                        className="mb-3"
                                        />

                                    <button 
                                        type="submit" 
                                        className="btn btn-secondary btn-user btn-block"
                                        disabled={isLoading}
                                        >{ isLoading ? 'Loading...' : 'Login' }</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default Login;