import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { printDate } from "../../Helpers/dateFormat";

const PosterCard = ({poster, setDeleteItem}) => {
    return (
        <div className={'col mb-4'}>
            <div className='poster-card'>
                <div className="poster">
                    <Link to={`/poster-details/${poster._id}`}>
                        <span>
                            <img src={process.env.REACT_APP_BASE_IMAGES_URL+poster.imagesUrls?.[0]} />
                        </span>
                    </Link>
                </div>
                <div className="px-3">
                    <Link to={`/poster-details/${poster._id}`}>
                        <h6 className="title">
                            {poster?.title}
                        </h6>
                        <p className="info">
                            {poster?.date} | {poster?.location}
                        </p>
                    </Link>
                    
                    <div className="d-flex justify-content-between mb-3">
                        <Button as={Link} to={`/artists/posters/edit/${poster._id}`} size="sm" className="flex-fill mr-1">
                            Edit
                        </Button>
                        
                        <Button onClick={() => setDeleteItem({id: poster?._id, url: 'poster/delete/'+poster?._id})} size="sm" variant="danger" className="flex-fill ml-1">
                            Delete
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PosterCard;