import { useEffect, useState } from 'react';
import { Link, useSearchParams } from "react-router-dom";
import useFetch from '../../useFetch';
import Paginate from '../../Paginate';
import DeletePopup from './../DeletePopup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Dropdown, Image, Table } from 'react-bootstrap';

const WorkerIndex = () => {
    document.title = "Workers | Mowing";
    
    const [apiUrl, setApiUrl] = useState(process.env.REACT_APP_BASE_API_URL+'/workers');

    const [items, setItems] = useState([]);
    const [meta, setMeta] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [deleteItem, setDeleteItem] = useState(null);

    const { response, isLoading, error: fetchError } = useFetch(apiUrl);

    useEffect(() => {
        setItems(response?.workers?.data ?? []);
        setMeta(response?.workers?.meta ?? []);
    }, [response])

    return (
        <div className="container-fluid">
            {deleteItem ? <DeletePopup setItems={setItems} deleteItem={deleteItem} setDeleteItem={setDeleteItem} /> : null}
            
            <div className="card shadow mb-4">
                <div className="card-header d-flex">
                    <h5 className="h5 mb-0 text-gray1-800 font-weight-bold text-primary mr-3">Workers</h5>
                    <h6 className="m-0 font-weight-bold text-primary ml-auto align-self-center text-right"><Link to="create">Add New</Link></h6>
                </div>
                <div className="card-body">
                    { fetchError && <div className="alert-msg-danger"><h3 className="mb-0"><strong>{ fetchError }</strong></h3></div> }

                    { isLoading && <h5 className="text-center my-3">Loading Data</h5> }
                    
                    { !fetchError && !isLoading ? (
                        <>
                            { items?.length == 0 ? <h5 className="text-center text-danger my-3">No data found{currentPage > 0 ? ' on this page' : ''}</h5> : (
                                <Table responsive bordered hover>
                                    <thead>
                                        <tr>
                                            <th style={{minWidth: '180px', width: '180px'}}>ID</th>
                                            <th style={{minWidth: '180px'}}>Name</th>
                                            <th style={{minWidth: '120px'}}>Working Hours</th>
                                            <th style={{minWidth: '120px'}}>Mow Rate</th>
                                            <th style={{width: '1px' }}>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { items?.map((item) => (
                                            <tr key={ item.id }>
                                                <td>{ item.id }</td>
                                                <td>{ item.name }</td>
                                                <td>{ item.daily_work_hours } hours</td>
                                                <td>{ item.mow_rate }m<sup>2</sup></td>
                                                <td style={{padding: '0.3rem'}}>
                                                    <Dropdown>
                                                        <Dropdown.Toggle variant="primary" className="btn-sm" id="dropdown-basic">Actions</Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item as={Link} to={`edit/${item.id}`}>Modify</Dropdown.Item>
                                                            <Dropdown.Item as={Link} to={`password/${item.id}`}>Change Password</Dropdown.Item>
                                                            <Dropdown.Item onClick={() => setDeleteItem({id: item.id, url: 'workers/'+item.id})} className="text-danger">Delete</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            ) }

                            {meta?.last_page > 1 ? (
                                <Paginate
                                    url={apiUrl}
                                    setUrl={setApiUrl}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    pageCount={meta?.last_page}
                                />
                            ) : null}

                            <i className="d-block text-center">{meta?.total ? `(${meta?.total} records found)` : null}</i>
                        </>
                    ) : null}
                </div>
            </div>
        </div>
    );
}
 
export default WorkerIndex;