import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Icon } from "@iconify/react";
import { store } from "../redux/store";
import { removeUser } from "../redux/userSlice";

const WorkerNavbar = () => {
    let navigate = useNavigate();

    const handleLogout = (e) => {
        e.preventDefault()
        
        fetch(process.env.REACT_APP_BASE_API_URL+'/logout', {
            method: 'POST',
            headers: { 
                "Accept": "application/json",
                "Authorization": "Bearer "+localStorage.getItem("token")
            },
        })
        
        store.dispatch(removeUser());
        navigate('/');
    }

    return ( 
        <nav className="navbar navbar-expand navbar-light topbar static-top shadow">
            {/* <div style={{
                position: 'absolute',
                zIndex: '-1',
                bottom: '0px',
                left: '0',
                height: '20px',
                backgroundImage: 'url(/grass.png)',
                width: '100%',
                backgroundSize: 'inherit',
            }}></div> */}
            <div className="container-fluid">
                <Navbar.Brand>
                    <Link to={"/admin/dashboard"}>
                        <img src="/logo.svg"/>
                    </Link>
                </Navbar.Brand>
                <ul className="navbar-nav">
                    <Nav.Link as={Link} to={"/admin/dashboard"}>Dashboard</Nav.Link>
                </ul>
                <ul className="navbar-nav">
                    <Nav.Link as={Link} to={"/admin/tasks"}>Tasks</Nav.Link>
                </ul>
                <ul className="navbar-nav ml-auto">
                    <Nav.Link as={Link} onClick={handleLogout} className="text-danger" style={{ fontWeight: 'normal' }}>Logout</Nav.Link>
                </ul>
            </div>
        </nav>
     );
}
 
export default WorkerNavbar;