import { useEffect, useState } from 'react';
import { constants } from './../Constants';
import { Icon } from '@iconify/react';
import { Button, Table } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useFetch from '../../useFetch';
import { printDate } from '../../Helpers/dateFormat';
import { store } from '../../redux/store';
import { removeUser } from '../../redux/userSlice';

const ExchangeHistory = () => {
    document.title = "환전 신청 내역 | Mowing";
    
    let navigate = useNavigate();
        
    const { user } = useSelector(state => state.user);
    
    useEffect(() => {
        if (!user.token) navigate('/login')
        else if (user.artistName == '-NA-') navigate('/artists/personal-information')
    }, [user])

    const { response, isLoadingFetch, errorFetch } = useFetch(process.env.REACT_APP_BASE_API_URL+'/exchange/all');

    return (
        <div className="container-fluid artist-details mb-4 pt-2">
            <div className='general-card donation-history-card mb-4'>
                <div className='general-card-header d-flex justify-content-between align-items-center mb-0'>
                    <h4 className="heading1 mb-0">환전 신청 내역</h4>
                    <Button as={Link} to={'/artists/exchange/create'} variant='tab' size='sm' className='mx-0'>
                        Create a request
                    </Button>
                </div>
                {isLoadingFetch ? (
                    <div className='text-center my-3'>
                        <img src='/loader.svg' style={{ width: '80px', height: '80px' }} />
                    </div>
                ) : (
                    errorFetch ? (
                        <h5 className='text-center text-danger py-4 mb-0'>{errorFetch}</h5>
                    ) : (
                        response?.exchanges?.length === 0 ? (
                            <h5 className='text-center py-4 mb-0'>No exchange request created yet</h5>
                        ) : (
                            <Table responsive bordered hover className='mb-0'>
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Hearts</th>
                                        <th>Amount</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {response?.exchanges?.map((item) => (
                                        <tr key={ item.id }>
                                            <td style={{ minWidth: '100px' }}><Link to={`/artists/exchange/${item?._id}`}>{printDate(item?.createdAt, true)}</Link></td>
                                            <td style={{ minWidth: '70px' }}>{item?.hearts}</td>
                                            <td style={{ minWidth: '90px' }}>{item?.amount}원</td>
                                            <td style={{ minWidth: '90px' }}>
                                                {item?.status == 1 ? <span className={`badge badge-table badge-warning`}>Pending</span> : null}
                                                {item?.status == 2 ? <span className={`badge badge-table badge-success`}>Approved</span> : null}
                                                {item?.status == 3 ? <span className={`badge badge-table badge-danger`}>Rejected</span> : null}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        )
                    )
                )}
            </div>
        </div>
    );
}
 
export default ExchangeHistory;