import { useEffect, useState, useRef } from 'react';
import { Accordion, Form } from 'react-bootstrap';
import { Checkbox, FormControlLabel, TextField } from '@mui/material';
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useFetch from '../../useFetch';

const PastureEdit = () => {
    document.title = "Modify Pasture | Mowing";
    
    const { id } = useParams();
    let navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [validationErrors, setValidationErrors] = useState([]);

    const cardRef = useRef();

    const [location, setLocation] = useState('')
    const [address, setAddress] = useState('')
    const [surface, setSurface] = useState('')
    const [comment, setComment] = useState('')
    const [emergencyLevel, setEmergencyLevel] = useState('')
    const [isProblem, setIsProblem] = useState(null)
    
    const { response } = useFetch(process.env.REACT_APP_BASE_API_URL+'/pastures/'+id);
    useEffect(() => {
        if (response?.data)
        {
            if (response.data.coordinates) setLocation(response.data.coordinates)
            if (response.data.address) setAddress(response.data.address)
            if (response.data.surface) setSurface(response.data.surface)
            if (response.data.manager_comments) setComment(response.data.manager_comments)
            if (response.data.emergency_level) setEmergencyLevel(response.data.emergency_level)
            if (response.data.is_problem) setIsProblem(response.data.is_problem ? true : false)
        }
    }, [response])

    const handleSubmit = (e) => {
        e.preventDefault();
        
        setIsLoading(true);
        setValidationErrors(false);
        setError(false);
        setSuccess(false);

        let formData = new FormData();
        formData.append("_method", "PUT");
        formData.append("address", address)
        formData.append("coordinates", location)
        formData.append("surface", surface)
        formData.append("manager_comments", comment)
        formData.append("emergency_level", emergencyLevel)
        if (isProblem) formData.append("is_problem", 1)
        
        fetch(process.env.REACT_APP_BASE_API_URL+'/pastures/'+id, {
            method: 'POST',
            headers: { 
                "Accept": "application/json",
                "Authorization": "Bearer "+localStorage.getItem("token")
            },
            body: formData
        })
        .then(response => {
            if (!response.ok) {
                throw Error('There was some error while editing pasture, please try again.');
            }

            return response.json();
        })
        .then(data => {
            cardRef.current.scrollIntoView();
            setIsLoading(false);

            if (data?.status == 'success')
            {
                setSuccess(data.message);
            }
            else if (data?.status == 'validation_error')
            {
                setValidationErrors(data.messages);
            }
            else if (data?.status == 'error' && data?.message)
            {
                setError(data.message);
            }
            else
            {
                setError('There was some error while editing pasture, please try again.');
            }
        })
        .catch(error => {
            cardRef.current.scrollIntoView();
            setError(error.message);
            setIsLoading(false);
        })
    }
    
    return (
        <div className="container-fluid">
            <div ref={cardRef} style={{ marginTop: '-80px', position: 'absolute' }}></div>
            <form onSubmit={handleSubmit} className="user">
                <div className="card shadow mb-4">
                    <div className="card-header d-flex">
                        <h5 className="h5 mb-0 text-gray1-800 font-weight-bold text-primary mr-3">Modify Pasture {response?.data?.id ? '(ID: '+response.data.id+')' : ''}</h5>
                        <h6 className="m-0 font-weight-bold text-primary ml-auto align-self-center text-right"><Link onClick={() => navigate(-1)} className="go-back mb-0">Go Back</Link></h6>
                    </div>
                    <div className="card-body">
                        { success && <div className="alert-msg-success"><h3 className="mb-0"><strong>{ success }</strong></h3></div> }
                        
                        { error && <div className="alert-msg-danger"><h3 className="mb-0"><strong>{ error }</strong></h3></div> }

                        { validationErrors.length > 0 &&
                            <div className="alert-msg-danger">
                                <h3 className="mb-1"><strong>There was some error in submitted information, please try again.</strong></h3>
                                <ul className="mb-0" style={{fontSize: '15px'}}>
                                    { validationErrors.map((validationError, index) => (
                                        <li key={index}>{ validationError }</li>
                                    ))}
                                </ul>
                            </div>
                        }

                        <TextField 
                            variant="outlined" 
                            label="Pasture location" 
                            value={location}
                            onChange={ (e) => setLocation(e.target.value) }
                            required={true}
                            style={{ width: '100%' }}
                            className="mb-3"
                            />

                        <TextField 
                            variant="outlined" 
                            label="Pasture address" 
                            value={address}
                            onChange={ (e) => setAddress(e.target.value) }
                            style={{ width: '100%' }}
                            className="mb-3"
                            />

                        <TextField 
                            variant="outlined" 
                            label="Pasture surface m2" 
                            value={surface}
                            onChange={ (e) => setSurface(e.target.value) }
                            style={{ width: '100%' }}
                            className="mb-3"
                            />

                        <TextField 
                            variant="outlined" 
                            label="Pasture comment (manager)" 
                            value={comment}
                            onChange={ (e) => setComment(e.target.value) }
                            style={{ width: '100%' }}
                            className="mb-3"
                            />

                        <TextField 
                            variant="outlined" 
                            label="Emergency level" 
                            value={emergencyLevel}
                            onChange={ (e) => setEmergencyLevel(e.target.value) }
                            style={{ width: '100%' }}
                            className="mb-3"
                            />
                        
                        <div className='mb-3'>
                            <label className='mb-1'>Is there any problem?</label>
                            <div>
                                <p onClick={() => setIsProblem(false)} className={`badge-checkbox ${isProblem === false ? 'success' : 'light'}`}>No</p>
                                <p onClick={() => setIsProblem(true)} className={`badge-checkbox ${isProblem === true ? 'danger' : 'light'}`}>Yes</p>
                            </div>
                        </div>

                        <button 
                            type="submit" 
                            disabled={isLoading}
                            className="btn btn-secondary btn-user btn-block"
                            >Save Changes</button>
                    </div>
                </div>
            </form>
        </div>
    );
}
 
export default PastureEdit;